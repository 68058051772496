import React from "react";
import { Layout } from "../../components";

export default function BlogIndexPage(): React.ReactElement {
  return (
    <Layout>
      <div style={{ marginTop: 100 }}>BlogsIndex page</div>
    </Layout>
  );
}
